<div class="container">
  <div class="wrapper">
    <div class="loaded-content" [class.blurred]="showSpinner">
      <div *ngIf="isLoggedIn">
        <div class="row">
          <div class="col-md-12">
            <h3>Puppy Referral Program - Find Listing</h3>
            <p>This is a listing of planned litters and litters and adults currently available for placement by NCA members. Each listing contains sire and dam information including titles and health clearances. Members listing litters agree to the following:</p>
            <ul>
              <li>Must have no complaints or formal grievances which the NCA Board or Mediation Committee has found to have a basis in fact and were unresolved. NCA members and non‐members may file complaints, only NCA members may file formal grievances.</li>
              <li>Must agree to always use a written Agreement/Contract in all Newfoundland transactions.</li>
              <li>Must agree to make a life‐long commitment to every Newfoundland they produce and to cooperate in the rescue of any Newfoundland dogs they produce.</li>
              <li>Must screen all puppies&apos; hearts at no earlier than 9 weeks old using a Board‐Certified (ACVIM) Cardiologist before placing and provide the screening results to the puppy buyer.</li>
              <li>Agrees to abide by the NCA Code of Ethics and to cooperate in the investigation of any written complaints lodged against them.</li>
            </ul>
            <p>If you have questions or require more assistance, please contact <a href="mailto:breederreferral@ncadogs.org">breederreferral@ncadogs.org</a></p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8 d-inline-block">
            <label for="zipcode">Enter ZipCode to sort listings:</label><input style="margin-left:10px;" type="text" id="zipcode" [(ngModel)]="zipcode"><button type="button" (click)="loadListingData()">Sort Listings</button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12 d-inline-block">
            <h3 class="d-inline-block">Approved Listings</h3><button class="d-inline-block" type="button" [cdkCopyToClipboard]="listings" style="margin-left:30px;">Copy Listings</button>
          </div>
        </div>
        <div class="row mb-3 d-none">
          <div class="col-md-12">
            <label for="clipboard-example-textarea">Text to be copied</label>
            <textarea id="clipboard-example-textarea" cols="30" rows="10" [(ngModel)]="listings"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <p class="fw-bold">Type</p>
          </div>
          <div class="col-md-1">
            <p class="fw-bold">DOB/Planned</p>
          </div>
          <div class="col-md-2">
            <p class="fw-bold">Breeder Info</p>
          </div>
          <div class="col-md-3">
            <p class="fw-bold">Sire Info</p>
          </div>
          <div class="col-md-3">
            <p class="fw-bold">Dam Info</p>
          </div>
          <div class="col-md-2">
            <p class="fw-bold">Desc</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="puppy-box" *ngFor="let listing of approved"><app-listing-find-card [item]="listing"></app-listing-find-card></div>
          </div>
        </div>
        <div class="row" *ngIf="approved.length === 0">
          <div class="col-md-12 text-center">
            <h3>No results found</h3>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoggedIn">
        <h2>Login to find listings</h2>
        <p>Log In here to review all approved puppy listings.</p>
        <div class="row">
          <div class="col-md-6 mx-auto">
            <h4>NCA Member Login</h4>
            <p>Please enter the Email Address and Password that you use to access the NCA Membership Portal.
              If you do not remember your login information or have never setup an account on the NCA Membership Portal,
              please click the link below to setup your password.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 mx-auto">
            <form id="initMemberRegForm" [formGroup]="memberForm">
              <div class="form-row">
                <div class="col">
                  <input type="text" class="form-control" id="emailMember" name="emailMember" formControlName="emailMember" placeholder="Email">
                </div>
                <div class="col">
                  <input type="password" class="form-control" id="pwdMember" name="pwdMember" formControlName="pwdMember" placeholder="Password">
                </div>
                <div class="col mt-3">
                  <button type="button" class="btn btn-primary" id="loginMemberButton" (click)="loginMember()" [disabled]="!memberForm.valid">Login</button>
                </div>
              </div>
              <div class="form-row mt-1">
                <div class="col-md-11">
                  <a style="color: #0000ff;" href="https://members.ncanewfs.org/Sys/ResetPasswordRequest" target="_blank">Forgot your password or need to create one for the first time?</a>
                </div>
              </div>
              <hr>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </div>
  </div>
</div>
